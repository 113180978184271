<template>
  <div class="wrapper">
    <HeaderComponent :config="config">
      <template v-slot:logo>
        <slot name="logo">
          <img
            :src="config.images.icon.webp"
            class="header__logo-image"
            alt="logo"
          />
        </slot>
      </template>
    </HeaderComponent>
    <main class="main">
      <div class="container">
        <div class="hero__inner">
          <div class="casino">
            <ul class="casino__cards casino__cards-1">
              <li
                v-for="{name, gameTitle, url, casinoBonus1} in config.projects"
                :key="name"
                class="casino-item"
                :class="name"
              >
                <a
                  class="casino-item__link"
                  :href="url"
                  target="_blank"
                >
                  <img
                    v-if="name"
                    :src="require(`./img/casino-${name}.webp`)"
                    class="casino-item__side-image"
                    alt="casino banner"
                  >
                  <div class="casino-item__content">
                    <div class="casino-item__top">
                      <img
                        v-if="name"
                        :src="require(`./img/casino-${name}.webp`)"
                        class="casino-item__image"
                        alt="casino banner"
                      >
                      <div class="casino-item__game-title">
                        <span>{{ gameBonus }} {{ gameTitle }} {{ gameBonusAfter }}</span>
                      </div>
                    </div>
                    <div class="casino-item__bonuses"  v-if="casinoBonus1">
                      <div class="casino-item__bonus" v-html="casinoBonus1 || config.casinoBonus1"></div>
                      <div class="casino-item__bonus" v-html="config.casinoBonus2"></div>
                    </div>
                    <div
                      class="casino-item__promocode"
                      @click.prevent="copyToClipboard(config.promocode)"
                    >
                      <span class="casino-item__your-promocode">{{ config.yourPromocode }}</span>
                      <span>{{ config.promocode }}</span>
                      <img
                        src="./img/icon-copy.svg"
                        class="casino-item__copy"
                        alt=""
                      >
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
    <FooterComponent :config="config"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import HeaderComponent from './blocks/header'
import FooterComponent from './blocks/footer'

export default defineComponent({
  name: 'template',
  props: {
    config: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    HeaderComponent,
    FooterComponent
  },
  computed: {
    gameBonus () {
      return this.config.gameBonus || ''
    },
    gameBonusAfter () {
      return this.config.gameBonusAfter || ''
    }
  },
  created () {
    const html = document.querySelector('html')
    const body = document.querySelector('body')
    document.addEventListener('scroll', (event) => {
      if (html.scrollTop > window.outerWidth * 3 / 100) {
        body.classList.add('sticky-top')

        return
      }

      body.classList.remove('sticky-top')
    })

    if (this.config.theme) {
      body.classList.add(`theme-${this.config.theme}`)
    }
  },
  methods: {
    async copyToClipboard (text) {
      try {
        await navigator.clipboard.writeText(text)
        this.$emit('copy')
      } catch ($e) {
        console.error('Cannot copy')
      }
    }
  }
})

// {
//   "code": 200,
//   "data": {
//   "images": {
//     "icon": {
//       "webp": "/upload/s3/landing/01jc0vj5gth52y5n0807ar5n7a/tfrz1pkedhc4g8csswwo080s48ckkcw.webp",
//         "fallback": "/upload/s3/landing/01jc0vj5gth52y5n0807ar5n7a/tfrz1pkedhc4g8csswwo080s48ckkcw.jpg"
//     }
//   },
//   "siteTitle": "ENCANTADOR (title)",
//     "blogerName": "ENCANTADOR (name)",
//     "socialsList": [
//     {
//       "name": "Twitch",
//       "url": "https://www.twitch.tv/cultusninety5"
//     },
//     {
//       "name": "Kick",
//       "url": "http://kick.com/cultus95"
//     },
//     {
//       "name": "Instagram",
//       "url": "https://www.instagram.com/cultusninety5/"
//     }
//   ],
//     "projects": [
//     {
//       "name": "",
//       "url": "https://1go-irit.com/c1912c988",
//       "gameTitle": "Book of Kemet"
//     },
//     {
//       "name": "",
//       "url": "https://mnr-irrs12.com/cc2c3e22e",
//       "gameTitle": "Book of Kemet"
//     },
//     {
//       "name": "",
//       "url": "https://drp-irrs12.com/cd50abd89",
//       "gameTitle": "Book of Kemet"
//     },
//     {
//       "name": "legzo",
//       "url": "https://gzo-irrs01.com/ce04c7668",
//       "gameTitle": "Book of Kemet",
//       "casinoBonus1": "bonus_first",
//       "casinoBonus2": "bonus_second"
//     },
//     {
//       "name": "izzi",
//       "url": "https://izzi-irrs01.com/c399d6f0e",
//       "gameTitle": "Book of Kemet"
//     },
//     {
//       "name": "starda",
//       "url": "https://strd-irrs12.com/c7a830a7a",
//       "gameTitle": "Book of Kemet"
//     },
//     {
//       "name": "jet",
//       "url": "https://jetb-intsemed2.com/c95043be5",
//       "gameTitle": "Book of Kemet"
//     },
//     {
//       "name": "fresh",
//       "url": "https://fresh-sfgjhyrt02.com/ceb065ec1",
//       "gameTitle": "Book of Kemet"
//     },
//     {
//       "name": "",
//       "url": "https://lex-irrs01.com/c21cb9cc1",
//       "gameTitle": "Book of Kemet",
//       "casinoBonus1": "bonus_first",
//       "casinoBonus2": "bonus_second"
//     }
//   ],
//     "headerText": "ENCANTADOR (header text)",
//     "yourPromocode": "ENCANTADOR (your promocode)",
//     "gameBonus": "Bónus no jogo Book of Kemet (BGaming) (game bonus)",
//     "promocode": "ENCANTADOR (promocode)",
//     "casinoBonus1": "No primeiro depósito (casino bonus first)",
//     "casinoBonus2": "50FS Para cadastro com código promocional (casino bonus second)",
//     "firstDeposit": "150%_first_deposit_field",
//     "copyMessage": "ENCANTADOR (copy message)",
//     "theme": "default_black"
// },
//   "dataUpdatedAt": "2024-11-13T19:27:14Z"
// }

</script>

<style lang="scss" src="./scss/style.scss"></style>
